import React from "react";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
      <>
        <Helmet>
          <title>
            Project Mercury - EDI Integration SaaS Platform
          </title>
        </Helmet>
        {/* End Page SEO Content */}
        <div className="full-height-layout d-flex align-items-center">
          <div className="overlay"></div>
          <div className="coming-soon-content font-gordita">
            <div className="logo coming-soon-brand row justify-content-center">
              <img src="images/logo/pm-color.png" alt="brand logo"/>
              <h6>COMING SOON</h6>
            </div>
            <h1 data-aos="fade-up">
              A cutting-edge EDI{" "}
              <span style={{color: "#e63946"}}>integration</span>,{" "}
              <span style={{color: "#457b9d"}}>translation</span>,{" and "}
              <span style={{color: "#a8dadc"}}>analytics</span> platform
            </h1>
            <div className="mx-4">
              <form onClick={handleSubmit}>
                <input type="email" placeholder="Enter your email"/>
                <button>Get Updates</button>
              </form>
            </div>
          </div>
        </div>
      </>
  );
};

export default ComingSoon;
