import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
      <>
        <Helmet>
          <title></title>
          <meta property="og:site_name" content="deski" />
          <meta property="og:type" content="website" />
          <meta
              property="og:title"
              content="Project Mercury - EDI Integration SaaS Platform"
          />
          <meta
              name="keywords"
              content="EDI Integration, EDI Software, Electronic Data Interchange, EDI Solutions, EDI SaaS Platform, EDI Cloud Services, B2B Integration, Supply Chain Automation, EDI Compliance, EDI Service Provider, Cloud-Based EDI, EDI Managed Services, EDI Implementation, EDI Consulting, EDI API Integration, EDI Connectivity, EDI Transaction Management, EDI Data Exchange, Automated EDI, EDI Integration Software, EDI System Integration, EDI Gateway, Real-Time EDI, EDI Network Services, EDI Onboarding, EDI Document Exchange, Secure EDI Transactions, EDI Mapping and Translation, EDI Integration Solutions, EDI for Retailers, saas, software, software company"
          />
          <meta
              name="description"
              content="Discover seamless EDI integration with our cutting-edge SaaS platform. Our EDI software solutions facilitate efficient electronic data interchange, ensuring compliance and secure transactions for B2B integration and supply chain automation. Benefit from our cloud-based EDI services, managed services, and real-time data exchange. Simplify your EDI implementation with expert consulting, API integration, and secure connectivity. Optimize your business operations with automated EDI, mapping, and translation solutions tailored for retailers and enterprises. Choose our platform for EDI transaction management, onboarding, and document exchange to enhance your EDI network services."
          />
          <meta name="description" content="Project Mercury - EDI Integration SaaS Platform" />
        </Helmet>
        {/* End Seo Helmt */}

        {/* End Scroll top */}

        <AllRoutes />
      </>
  );
};

export default App;
